import React from 'react'

import BaseLayout from '../templates/base'
import Head from '../components/head'
import SocialCard from '../components/social-card'
import PageHeader from '../components/page-header'

import config from '../../config/site-config'
import formatHtmlTitle from '../utils/formatHtmlTitle'

const AboutPage = ({data}) => (
  <BaseLayout>
    <Head title={formatHtmlTitle(`About`)} />
    <SocialCard title={formatHtmlTitle(`About`)} />
    <PageHeader title={<span>Hi, I’m Harshil! <span className="about-wave">👋🏽</span></span>} />
    <div className="about-page">
        <p>I’m an iOS engineer living in Mumbai, India.</p>
        <p>I make <a href="https://sneakpeak.app" title="Peak">Peak</a>, an iPhone & iPad app that lets you build your own fitness dashboard, and <a href="/pause" title="Pause">Pause</a>, a Mac app that reminds you to rest.</p>
        <p>I’m currently looking for full-time work opportunities. If youʼd like to talk, please feel free to <a title={`Send an email to ` + config.social.email.username} href={config.social.email.url}>email me</a>.</p>
        <p>I sometimes work on open source projects in my free time, which can be found on my <a href="https://github.com/HarshilShah">GitHub</a>. Previously, I have worked as an iOS engineer at <a title="Juice" href="https://twitter.com/sendjuice">Juice</a>, and <a title="Stopwatch" href="https://jonw.com/stopwatch">Stopwatch</a>.</p>
        <p>You can usually find me on <a href={config.social.mastodon.url}>Mastodon</a>, or send me an <a title={`Send an email to ` + config.social.email.url} href={config.social.email.url}>email</a>.</p>

        <h3>Colophon</h3>
        <p>This website is built using <a href="https://gatsbyjs.org">Gatsby</a>, and hosted on <a href="https://pages.cloudflare.com">CloudFlare Pages</a>. Text is set in San Francisco & New York, and code in San Francisco Mono. More about how I built it soon.</p>
    </div>
  </BaseLayout>
)

export default AboutPage
